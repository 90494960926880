import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      data: null,
      dialog: false,
      loading: false,
      alert: false,
      loadingDeny: false,
      moment,
      dayTranslations: {
        DOMINGO: "Domingo",
        SEGUNDA_FEIRA: "Segunda-feira",
        TERCA_FEIRA: "Terça-feira",
        QUARTA_FEIRA: "Quarta-feira",
        QUINTA_FEIRA: "Quinta-feira",
        SEXTA_FEIRA: "Sexta-feira",
        SABADO: "Sábado",
      },
    };
  },

  methods: {
    formateDate(data) {
      var date = "";
      if (data.schedule) {
        if ("daily" in data.schedule) {
          date = data.schedule?.daily?.startDate.split("-");
          return (date = date[2] + "/" + date[1] + "/" + date[0]);
        } else if ("weekly" in data.schedule) {
          date = data.schedule?.weekly?.startDate.split("-");
          return (date = date[2] + "/" + date[1] + "/" + date[0]);
        } else if ("monthly" in data.schedule) {
          date = data.schedule?.monthly?.startDate.split("-");
          return (date = date[2] + "/" + date[1] + "/" + date[0]);
        } else if ("single" in data.schedule) {
          date = data.schedule?.single?.date.split("-");
          return (date = date[2] + "/" + date[1] + "/" + date[0]);
        } else {
          console.log("Custom!");
        }
      } else {
        date = data.date.split("-");
        return (date = date[2] + "/" + date[1] + "/" + date[0]);
      }
    },

    async approveConsent() {
      this.loading = true;
      const body = { consentInteractionId: this.data.interactionId };
      await axios
        .post(
          `api/${process.env.VUE_APP_ORG_NAME}/consents/v1/interactions/consent`,
          body
        )
        .then((response) => {
          window.open(response.data, "_self");
        })
        .catch((error) => {
          console.log(error);
          this.alert = true;
        });
      this.loading = false;
    },

    async cancelConsent() {
      this.loadingDeny = true;
      const body = { consentInteractionId: this.data.interactionId };
      await axios
        .post(
          `api/${process.env.VUE_APP_ORG_NAME}/consents/v1/interactions/cancel`,
          body
        )
        .then((response) => {
          this.loadingDeny = false;
          this.dialog = false;
          window.open(response.data, "_self");
        })
        .catch((error) => console.log(error));
    },
  },

  computed: {
    formatCpf() {
      const badchars = /[^\d]/g;
      const mask = /(\d{3})(\d{3})(\d{3})(\d{2})/;
      const cpf = new String(this.data.consent.creditor.cpfCnpj).replace(
        badchars,
        ""
      );
      return cpf.replace(mask, "$1.$2.$3-$4");
    },

    formatCnpj() {
      const badchars = /[^\d]/g;
      const mask = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
      const cnpj = new String(this.data.consent.creditor.cpfCnpj).replace(
        badchars,
        ""
      );
      return cnpj.replace(mask, "$1.$2.$3/$4-$5");
    },
  },

  beforeMount() {
    this.data = this.$route.params.data;
    if (!this.data) {
      this.$router.push({ path: "/" });
    }
  },
};
