import axios from "axios";
export default {
    data() {
        return {
            data: null,
            opened: 0,
            checkbox: true,
            loadingConsent: false,
            loadingDeny: false,
            modal: false,
            itemInfo: null,

            requiredAccounts: false,
            requiredCustomers: false,
            requiredCreditCards: false,
            requiredCreditOperation: false,

            panel: 0
        };
    },

    methods: {

        async approveConsent() {
            this.loadingConsent = true;
            const body = { consentInteractionId: this.data.interactionId };
            await axios
                .post(`api/${process.env.VUE_APP_ORG_NAME}/consents/v1/interactions/consent`, body)
                .then((response) => {
                    this.loading = false;
                    window.open(response.data, "_self");
                })
                .catch((error) => console.log(error));
        },

        async cancelConsent() {
            this.loadingDeny = true;
            const body = { consentInteractionId: this.data.interactionId };
            await axios
                .post(`api/${process.env.VUE_APP_ORG_NAME}/consents/v1/interactions/cancel`, body)
                .then((response) => {
                    this.loading = false;
                    this.modal = false;
                    window.open(response.data, "_self");
                })
                .catch((error) => console.log(error));
        },

        decodeDate(date) {
            const currentExpirationDateTime = new Date(date);
            var day = currentExpirationDateTime.getDate();
            var month = currentExpirationDateTime.getMonth() + 1;
            const year = currentExpirationDateTime.getFullYear();

            if (day < 10) {
                day = `0${day}`;
            }

            if (month > 9) {
                return `${day}/${month}/${year}`;
            } else {
                return `${day}/0${month}/${year}`;
            }
        },


        setPermissions() {
            const permissions = this.data.consent.permissions;
            if (
                permissions.includes('CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ') &&
                permissions.includes('CUSTOMERS_PERSONAL_ADITTIONALINFO_READ')
            ) {
                this.customersPersonal = true;
                this.requiredCustomers = true;
            }

            if (
                permissions.includes('ACCOUNTS_READ') &&
                permissions.includes('ACCOUNTS_BALANCES_READ') &&
                permissions.includes('ACCOUNTS_OVERDRAFT_LIMITS_READ') &&
                permissions.includes('ACCOUNTS_TRANSACTIONS_READ')
            ) {
                this.accounts = true;
                this.requiredAccounts = true;
            }

            if (
                permissions.includes('CREDIT_CARDS_ACCOUNTS_READ') &&
                permissions.includes('CREDIT_CARDS_ACCOUNTS_LIMITS_READ') &&
                permissions.includes('CREDIT_CARDS_ACCOUNTS_TRANSACTIONS_READ') &&
                permissions.includes('CREDIT_CARDS_ACCOUNTS_BILLS_READ') &&
                permissions.includes('CREDIT_CARDS_ACCOUNTS_BILLS_TRANSACTIONS_READ')
            ) {
                this.creditCards = true;
                this.requiredCreditCards = true;
            }

            if (
                permissions.includes('LOANS_READ') &&
                permissions.includes('LOANS_WARRANTIES_READ') &&
                permissions.includes('LOANS_SCHEDULED_INSTALMENTS_READ') &&
                permissions.includes('LOANS_PAYMENTS_READ') &&
                permissions.includes('FINANCINGS_READ') &&
                permissions.includes('FINANCINGS_WARRANTIES_READ') &&
                permissions.includes('FINANCINGS_SCHEDULED_INSTALMENTS_READ') &&
                permissions.includes('FINANCINGS_PAYMENTS_READ') &&
                permissions.includes('UNARRANGED_ACCOUNTS_OVERDRAFT_READ') &&
                permissions.includes('UNARRANGED_ACCOUNTS_OVERDRAFT_WARRANTIES_READ') &&
                permissions.includes('UNARRANGED_ACCOUNTS_OVERDRAFT_SCHEDULED_INSTALMENTS_READ') &&
                permissions.includes('UNARRANGED_ACCOUNTS_OVERDRAFT_PAYMENTS_READ') &&
                permissions.includes('INVOICE_FINANCINGS_READ') &&
                permissions.includes('INVOICE_FINANCINGS_WARRANTIES_READ') &&
                permissions.includes('INVOICE_FINANCINGS_SCHEDULED_INSTALMENTS_READ') &&
                permissions.includes('INVOICE_FINANCINGS_PAYMENTS_READ') &&
                permissions.includes('CREDIT_CARDS_ACCOUNTS_READ') &&
                permissions.includes('CREDIT_CARDS_ACCOUNTS_READ')
            ) {
                this.creditOperation = true;
                this.requiredCreditOperation = true;
            }
        },        
    },

    computed:{
        formatCpf() {
			const badchars = /[^\d]/g
			const mask = /(\d{3})(\d{3})(\d{3})(\d{2})/
			const cpf = new String(this.data.consent.loggedUser.document.identification).replace(badchars, "");
			return cpf.replace(mask, "$1.$2.$3-$4");
		},
    },

    beforeMount() {
        this.data = this.$route.params.data;
        if (!this.data) {
            this.$router.push({path: "/"});
        }else{
            this.setPermissions();
        }
    },
};