import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        themes:{
            light:{
                fpink: '#FF0060',
                primary:'#8E19FF'
            }
        }
    }
});
