<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<script>

console.log('LOADING APPLICATION');

console.log(process.env.VUE_APP_PROXY_URL);

export default {
  name: "App",
};
</script>

<style>
  /* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Ruda:wght@400;500;600;700;800;900&display=swap'); */

  #app {
    /* font-family: 'Ruda', sans-serif; */
    background: url('./assets/background-image.jpg') no-repeat fixed  ;
    background-size: cover;
  }
</style>



