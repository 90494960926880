import Vue from "vue"
import VueRouter from "vue-router"
import loginView from '../views/login-view/login-view.vue'
import consentView from '../views/consent-view/consent-view.vue'
import paymentView from "../views/payment-view/payment-view.vue"
    
Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        name: 'loginView',
        component: loginView
    },
    {
        path: '/consent',
        name: 'consentView',
        component: consentView
    },
    {
        path: '/payment',
        name: 'paymentView', 
        component: paymentView       
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router