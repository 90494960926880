import axios from "axios";

export default {
  data() {
    return {
      user: null,
      password: null,
      interactionId: null,
      consentId: null,
      loading: false,
      modal: false,
      unauthorized: false,
      opt: "",
      length: 4,
      data: null,
      erroMessage: false
    };
  },

  beforeMount() {
    this.interactionId = this.$route.query.interactionId;
    this.consentId = this.$route.query.consentId;
    localStorage.setItem("organizationName", "SmartBank");
  },

  methods: {
    async approveConsent() {
      this.loading = true;
      const body = { consentInteractionId: this.data.interactionId };
      await axios
        .post(
          `api/${process.env.VUE_APP_ORG_NAME}/consents/v1/interactions/consent`,
          body
        )
        .then((response) => {
          this.loading = false;
          window.open(response.data, "_self");
        })
        .catch((error) => console.log(error));
    },

    async nextPage(isSimulator) {
      this.loading = true;

      const body = {
        user: this.user,
        password: this.password,
        interactionId: this.interactionId,
        consentId: this.consentId
      };

      await axios
        .post(
          `api/${process.env.VUE_APP_ORG_NAME}/consents/v1/interactions/login`,
          body
        )
        .then((response) => {
          // this.modal = true;
          this.data = response.data;
          
          if (isSimulator == false) {
            this.loading = false;
            if (this.data.consent.kind === "DATA") {
              this.$router.push({
                name: "consentView",
                params: { data: this.data }
              });
            }
            if (this.data.consent.kind === "PAYMENT") {
              this.$router.push({
                name: "paymentView",
                params: { data: this.data }
              });
            }
          } else {
            this.approveConsent();
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.modal = true;
            this.loading = false;
            this.unauthorized = true;
          } else {
            console.log(error);
            this.loading = false;
            this.erroMessage = true;
          }
        });
    },

    redirect() {
      window.open("http://localhost:8080/", "_self");
    },

    securityValidation() {
      if (
        this.opt ==
        this.data.consent.loggedUser.document.identification.slice(-4)
      ) {
        if (this.data.consent.kind == "PAYMENT") {
          this.$router.push({
            name: "paymentView",
            params: { data: this.data }
          });
        } else {
          this.$router.push({
            name: "consentView",
            params: { data: this.data }
          });
        }
      } else {
        this.unauthorized = true;
      }
    }
  },
  computed: {
    isActive() {
      return this.opt.length === 4;
    }
  }
};
